import "../components/pages/Contact.scss";

import { Col, Row } from "react-styled-flexboxgrid";
import React, {useState} from "react";

import ArrowDownRight from "../img/decoration/arrow-down-right.svg";
import ArrowRight from "../img/arrow-right.svg";
import Layout from "../components/layout/Layout";
import PropTypes from "prop-types";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../components/partials/SectionTitle/SectionTitle.js";
import { StyledGrid } from "../helpers/helpers.js";
import { graphql } from "gatsby";
import { navigate } from "gatsby-link";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export const ContactPageTemplate = ({
  title,
  highlighted,
  subtitle,
  address,
}) => {
  const [form, setForm] = useState({});
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formEl = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": formEl.getAttribute("name"),
        ...form,
      }),
    })
      .then(() => navigate(formEl.getAttribute("action")))
      .catch((error) => alert(error));
  };

  return (
    <StyledGrid className="ContactPage">
        <ScrollAnimation animateIn="fadeInBottom" duration={1}>
          <Row>
            <SectionTitle text={title} highlight={highlighted} isH1={true} />
            <Col xs={12} md={7} mdOffset={2}>
              <p>{subtitle}</p>
            </Col>
            <Col xs={3} className="deco">
              <img className="arrow" src={ArrowDownRight} alt="" />
            </Col>
          </Row>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInBottom" duration={1} delay={250}>
          <Row>
            <Col xs={12} md={3} mdOffset={1}>
              <h2>Nos coordonnées</h2>
              <p className="with-circle">{address}</p>
            </Col>
            <Col xs={12} md={6} mdOffset={1}>
              <form
                name="contact"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={(e) => handleSubmit(e)}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:
                    <input name="bot-field" onChange={(e) => handleChange(e)} />
                  </label>
                </div>
                <div className="field half">
                  <label className="label" htmlFor={"lastName"}>
                    Nom
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={"text"}
                      name={"lastName"}
                      onChange={(e) => handleChange(e)}
                      id={"lastName"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field half">
                  <label className="label" htmlFor={"firstName"}>
                    Prénom
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={"text"}
                      name={"firstName"}
                      onChange={(e) => handleChange(e)}
                      id={"firstName"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"enterprise"}>
                    Société
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={"text"}
                      name={"enterprise"}
                      onChange={(e) => handleChange(e)}
                      id={"enterprise"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"email"}>
                    E-mail
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={"email"}
                      name={"email"}
                      onChange={(e) => handleChange(e)}
                      id={"email"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={"message"}>
                    Message
                  </label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      name={"message"}
                      onChange={(e) => handleChange(e)}
                      id={"message"}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <button className="GroupButton" type="submit">
                    <span className="buttonLabel">ENVOYER LE MESSAGE</span>
                    <span className="button">
                      <img src={ArrowRight} alt="" />
                    </span>
                  </button>
                </div>
              </form>
            </Col>
          </Row>
        </ScrollAnimation>
      </StyledGrid>
  );
};

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
};

const ContactPage = ({ data }) => {
  const { markdownRemark } = data;
  const post = markdownRemark;
  return (
    <Layout>
      <ContactPageTemplate
        title={post.frontmatter.title}
        highlighted={post.frontmatter.highlighted}
        address={post.frontmatter.address}
        subtitle={post.frontmatter.subtitle}
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        address
        highlighted
      }
    }
  }
`;
